<template>
  <div :class="{goodsItem: true, cross: type === 'cross'}">

    <div class="imgBox" style="width: 55px;height: 55px">
      <van-image :src="product.mainPic" style="width: 55px;height: 55px;" alt=""/>
      <div class="mask"></div>
      <div class="time" >
        <span style="margin-left: auto; margin-right: auto;">已下架</span>
      </div>
    </div>
    <div class="goodsInfo">
      <div class="goodsName van-multi-ellipsis--l2">
<!--        <span class="ys" v-show="product.libckflag ===0">预售</span>-->
<!--        <span class="xh" v-show="product.libckflag ===1">现货</span>-->
        {{ product.name }}
      </div>

      <div class="handle">
        <div class="price">
        </div>

        <div class="btn" @click="goForFeedback">去反馈</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "productItem",

  props: {
    type: {
      type: String,
      default: 'vertical' //cross

    },
    product: {
      type: Object,
      "default": {},
    },
    width: {
      type: String,
      default: '4.58667rem'
    }
  },
  data() {
    return {
      time: 30 * 60 * 60 * 1000,
    }
  },
  methods:{
    goForFeedback(){
      this.$dialog.confirm({
        title: '提示',
        message: `确认把该消息反馈给厂家`,
      }).then(() => {

      })
    }
  }
}

</script>
<style lang="less" scoped>

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255 255 255 / 70%); /* 半透明遮罩层 */
  mix-blend-mode: multiply; /* 使遮罩层与图片相乘 */
  pointer-events: none; /* 使遮罩层不接收鼠标事件 */
}
.goodsItem {
  border-radius: 8px;
  width: 172px;
  background-color: #fff;
  margin-top: 10px;
  padding-bottom: 1px;
  &.cross {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px 0 5px 10px;

    .imgBox {
      margin-right: 7px;

      img {
        border-radius: 4px;
        height: 116px;
        width: 139px;
      }

      .time {
        font-size: 10px;
        padding: 0 7px;
      }

      .van-count-down {
        font-size: 10px;
      }
    }

    .goodsInfo {
      padding-top: 8px;
      flex: 1;
      .handle {
        margin-bottom: 0;
      }

      .tagList {
        padding-bottom: 12px;
      }
    }


  }

  .imgBox {
    position: relative;
    font-size: 0;

    .van-image {
      border-radius: 8px 8px 0 0;
      height: 144px;
      width: 172px;
      overflow: hidden;
    }


    .time {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 0 0.42667rem;
      height: 0.64rem;
      border-radius: 0.13333rem 0.13333rem 0px 0px;
      background: #a9a4a4;
      display: -webkit-box;
      display: -webkit-flex;
      display: flex;
      -webkit-box-align: center;
      -webkit-align-items: center;
      align-items: center;
      -webkit-box-pack: justify;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      font-size: 0.36667rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }

    .van-count-down {
      font-size: 10px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #D53A26;
    }
  }


  .goodsInfo {
    padding: 7px 11px 0 11px;

    .goodsName {
      font-size: 15px;
      color: #333333;
      line-height: 18px;
      height: 36px;

      .ys {
        width: 32px;
        //background: linear-gradient(264deg, #4DEECB, #32D5B4);
        background: linear-gradient(264deg, #f58130, #ef2514);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;
        line-height: 12px;
        text-align: center;
        padding: 2px 0;
        display: inline-block;
        margin-right: 3px;

      }

      .xh {
        width: 32px;
        background: linear-gradient(264deg, #4db3ee, #00A0E9);
        border-radius: 2px;

        font-size: 12px;
        font-weight: 500;
        color: #F4F4F4;
        line-height: 12px;
        text-align: center;
        padding: 2px 0;
        display: inline-block;
        margin-right: 3px;

      }
    }


    .tagList {
      padding: 5px 0 5px 0;

      .tag {
        border: 1px solid #FF0000;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #FF0000;
        line-height: 10px;
        display: inline-block;
        margin-right: 5px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
      .tagqh{
        border: 1px solid #363636;
        border-radius: 2px;
        padding: 3px 7px;
        font-size: 10px;
        font-weight: 400;
        color: #343434;
        line-height: 10px;
        display: inline-block;
        margin-right: 5px;

        &.plain {
          padding-left: 0;
          padding-right: 0;
          color: #666;
          border: none;
        }
      }
    }

    .handle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 27px;
      margin-bottom: 10px;

      .price {
        color: #D53A26;
        font-weight: 500;

        span:first-child {
          font-size: 12px;
          margin-right: 3px;
          font-weight: bold;
        }

        span:last-child {
          font-size: 19px;
          font-weight: bold;
        }
      }

      .btn {
        width: 65px;
        height: 20px;

        background: #DE3926;
        border-radius: 100px;

        font-size: 11px;
        font-weight: 400;
        color: #fff;

        line-height: 20px;
        text-align: center;

      }
    }
  }
}
</style>
