<template>
  <div class="indexHomePage page-gray">
    <van-field
        readonly
        clickable
        label="学校"
        :value="school"
        placeholder="选择学校"
        @click="resultPicker = true"
    />
    <van-popup v-model="resultPicker" round position="bottom">
      <van-picker
          show-toolbar
          :columns="resultList"
          title="选择学校"
          @cancel="resultPicker = false"
          @confirm="onResultConfirm"
      >
        <template #option="option">
          <div style="display: flex; flex-direction: column; align-items: center;">
            <div>{{ option.name }}</div>
          </div>
        </template>
      </van-picker>
    </van-popup>

    <div class="activitiesOfGoods">
      <div class="content">
        <div class="goodsList" v-for="(item,index) in productList" :key="index">
          <product-item :product.sync="item" type="cross"></product-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductItem from "./goods/productItem.vue";

export default {
  name: "overdueList",
  components: {ProductItem},
  data() {
    return {
      resultPicker: false,
      resultList: [],
      school: '',//学校
      productList:[],
    }
  },
  created() {
    //查询用户注册的所有学校
    this.$http.get(this.baseMallUrl + `/h5_mall/queryUserSchool`).then(res => {
      this.resultList = res.data;
    })

  },
  methods:{
    onResultConfirm(item) {
      this.school = item.name;
      this.resultPicker = false
      this.$http.get(this.baseProductUrl + `/h5_product/queryProductBySchool/${item.id}`).then(res => {
        this.productList = res.data;
      })
    },
  }
}
</script>



<style scoped lang="less">

</style>
